import { useState } from 'react';
import logo from '../img/DFKLogo.png';
import menu from '../img/menu.svg';
import cx from 'classnames';
import styles from './Header.module.scss';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faInstagram, faReddit, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const socialLinks = [
  {
    icon: faTwitter,
    label: 'Twitter',
    link: 'https://twitter.com/DefiKingdoms',
  },
  {
    icon: faReddit,
    label: 'Reddit',
    link: 'https://www.reddit.com/r/DefiKingdoms/',
  },
  {
    icon: faDiscord,
    label: 'Discord',
    link: 'https://discord.gg/defikingdoms',
  },
  {
    icon: faTelegram,
    label: 'Telegram',
    link: 'https://t.me/defikingdoms',
  },
  {
    icon: faYoutube,
    label: 'YouTube',
    link: 'https://www.youtube.com/c/defikingdoms',
  },
  {
    icon: faInstagram,
    label: 'Instagram',
    link: 'https://www.instagram.com/defikingdoms/',
  },
];

const Header = () => {
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  function toggleLinksDropdown() {
    setShowMobileDropdown(!showMobileDropdown);
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <nav className={cx(styles.dropdownContent, { [styles.show]: showMobileDropdown })}>
          <ul className={styles.headerCenter}>
            <li className={styles.dropdownContainer}>
              <button className="navDropDownLink">Info &#9662;</button>
              <ul className={styles.dropdownLinks}>
                <li>
                  <a href="https://docs.defikingdoms.com" target="_blank" rel="noreferrer">
                    Docs
                  </a>
                </li>
                <li>
                  <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                    Tutorial
                  </a>
                </li>
                <li>
                  <a href="https://build.defikingdoms.com/" target="_blank" rel="noreferrer">
                    Builders
                  </a>
                </li>
                <li>
                  <a href="/sundered-isles" target="_blank">
                    Sundered Isles
                  </a>
                </li>
                <li>
                  <a href="/crystalvale" target="_blank">
                    Crystalvale
                  </a>
                </li>
                <li>
                  <a href="/serendale" target="_blank">
                    Serendale
                  </a>
                </li>
                <li>
                  <a
                    href="https://dexscreener.com/avalanchedfk/defikingdoms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Charts
                  </a>
                </li>
                <li>
                  <a
                    href="https://solidity.finance/audits/DefiKingdoms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Audit
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/news">News</NavLink>
            </li>
            <li>
              <a href="https://merch.defikingdoms.com/" target="_blank" rel="noreferrer">
                Merch
              </a>
            </li>
            <li>
              <NavLink className={styles.buyJewel} to="/web3#buyjewel">
                Buy JEWEL
              </NavLink>
            </li>
            {/* <li>
              <a href="/contact" target="_blank" rel="noreferrer">
                Contact
              </a>
            </li> */}
            <li className={styles.hideOnDesktop}>
              <a href="https://docs.defikingdoms.com" target="_blank" rel="noreferrer">
                Docs
              </a>
            </li>
            <li className={styles.hideOnDesktop}>
              <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                Tutorial
              </a>
            </li>
            <li className={styles.hideOnDesktop}>
              <a href="https://build.defikingdoms.com/" target="_blank" rel="noreferrer">
                Builders
              </a>
            </li>
            <li className={styles.hideOnDesktop}>
              <a href="/crystalvale" target="_blank">
                Crystalvale
              </a>
            </li>
            <li className={styles.hideOnDesktop}>
              <a href="/serendale" target="_blank">
                Serendale
              </a>
            </li>
            <li className={styles.hideOnDesktop}>
              <a
                href="https://dexscreener.com/avalanchedfk/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                Charts
              </a>
            </li>
            <li className={styles.hideOnDesktop}>
              <a
                href="https://solidity.finance/audits/DefiKingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                Audit
              </a>
            </li>
          </ul>
        </nav>
        <div className={styles.headerRight}>
          <ul className={styles.social}>
            {socialLinks.map((social) => (
              <li className="icon" key={social.label}>
                <a href={social.link} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={social.icon} />
                  <span className="sr-only">{social.label}</span>
                </a>
              </li>
            ))}
          </ul>
          <a href="https://game.defikingdoms.com" className={styles.playButton}>
            Play
          </a>
          <div className={styles.mobileNav}>
            <img src={menu} className="toggle-menu" onClick={toggleLinksDropdown} alt="" />
          </div>
        </div>
      </header>
      <div className={styles.headerPlaceholder} />
    </>
  );
};

export default Header;
