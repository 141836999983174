import cx from 'classnames'
import { ReactNode } from 'react';
import styles from './LeftCopySection.module.scss'
import { SectionHeader } from './SectionHeader';

type LeftCopySectionProps = { className?: string, header: string; copy: ReactNode; image: string }

export function LeftCopySection({ className, header, copy, image }: LeftCopySectionProps) {
  return (
    <section className={cx(styles.leftSection, className)}>
      <div className={styles.leftColumn}>
        <SectionHeader text={header} />
        <div className={styles.rightCopy}>{copy}</div>
      </div>
      <div className={styles.rightColumn}>
        <img src={image} alt="" />
      </div>
    </section>
  )
}
