import './index.scss';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getCurrentLanguage } from '../../features/i18n/utils';
import LanguagePicker from '../../features/i18n/components/LanguagePicker';
import sdLogo from './img/dfk-serendale-logo.png';
import jadeLogo from './img/jade-large.png';
import kaiaIcon from './img/kaia-logo.svg';
import jewelToken from './img/jewel_token_x4.png';
import dfkChainBrown from './img/dfk-chain-logo-brn.svg';
import kaiaBrown from './img/kaia-logo-brn.svg';
import harmonyLogo from './img/harmony-one-logo.svg';
import traderFrame from './img/sd-landing-page-trader.png';
import jewelerFrame from './img/sd-landing-page-jeweler.png';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faFacebook, faInstagram, faReddit, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const settings = {
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
  ],
};

const SerendaleLanding = () => {
  const { t } = useTranslation();
  const [instructionIndex, setInstructionIndex] = useState(0);

  useEffect(() => {
    console.log(getCurrentLanguage());
  }, []);

  return (
    <div className="serendaleLanding">
      <LanguagePicker />

      <div className="hero textCenter">
        <div className="logo">
          <img src={sdLogo} alt="" />
        </div>
        <a
          href="https://game.defikingdoms.com"
          className="primary"
          target="_blank"
          rel="noreferrer"
        >
          {t('Start Playing')}
        </a>
      </div>

      <div className="howTo">
        <div className="container">
          <h1 className="textCenter">{t('How to Enter Serendale')}</h1>
          <h2 className="textCenter mobileLeft">
            {t('Where are you coming from? Click on your chain below')}:
          </h2>
          <div className="choices textCenter">
            <div
              className={cx('choice', 'kaia', {
                active: instructionIndex === 0,
              })}
              onClick={() => setInstructionIndex(0)}
            >
              <img src={kaiaBrown} alt="" />
            </div>
            <div
              className={cx('choice', 'dfkchain', {
                active: instructionIndex === 1,
              })}
              onClick={() => setInstructionIndex(1)}
            >
              <img src={dfkChainBrown} alt="" />
            </div>
            <div
              className={cx('choice', 'harmony', {
                active: instructionIndex === 2,
              })}
              onClick={() => setInstructionIndex(2)}
            >
              <img src={harmonyLogo} alt="" />
            </div>
            <div
              className={cx('choice', 'unknown', {
                active: instructionIndex === 3,
              })}
              onClick={() => setInstructionIndex(3)}
            >
              ?
            </div>
          </div>
          <div
            className={cx('instructions', 'kaia', {
              active: instructionIndex === 0,
            })}
          >
            <h1 className="textCenter">{t('Kaia')}</h1>
            <p>
              {t(
                'If your wallet (Kaikas or Metamask) is already configured for Kaia, your first stop will be to',
              )}{' '}
              <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                https://game.defikingdoms.com
              </a>
              .
            </p>
            <p>
              {t(
                "If this is your first time playing, you'll just click “Start Playing” and the game will prompt you to create an account name and select an avatar.",
              )}
            </p>
            <p>
              {t(
                "You might be surprised to see that you don't have to create any login credentials. This is because your DeFi Kingdoms account is linked to your wallet. Any time you log in from a browser that has Metamask installed (and you're logged in to your wallet, of course), DeFi Kingdoms will recognize you and load your profile.",
              )}
            </p>
            <p>
              {t(
                "You're now a fully established player of DeFi Kingdoms. There will be plenty to do on launch day.",
              )}
            </p>
            <img src={traderFrame} alt="" />
            <p>
              {t(
                'Your first stop should be to head to the Marketplace and visit the Trader to swap your tokens for the Serendale Power Token, JADE. To get started with gameplay you might want to head to the Tavern to buy a Hero.',
              )}{' '}
              {t('For more information on purchasing a Hero see here')}:{' '}
              <a
                href="https://docs.defikingdoms.com/gameplay/locations/tavern"
                target="_blank"
                rel="noreferrer"
              >
                https://docs.defikingdoms.com/gameplay/locations/tavern
              </a>
            </p>
            <p>
              {t(
                'Another place to start is the Gardens. The Gardens will offer incentivized pools that emit JADE tokens as a reward for providing liquidity. There will be both unlocked and locked JADE rewards, with an initially high emission rate that declines after each epoch. Garden emissions will begin 24 hours after Vesting and Locked JADE airdrops have been completed.',
              )}
            </p>
            <p>{t('The initial pairings available in the Gardens will be as follows')}:</p>
            <ul>
              <li>JADE:JEWEL</li>
              <li>JADE:KAIA</li>
              <li>JADE:AVAX</li>
              <li>JADE:oUSDT</li>
              <li>JADE:oWBTC</li>
              <li>JADE:oETH</li>
              <li>JEWEL:KAIA</li>
              <li>JEWEL:AVAX</li>
              <li>JEWEL:oUSDT</li>
              <li>JEWEL:oWBTC</li>
              <li>JEWEL:oETH</li>
            </ul>
            <p>
              {t('For more information on the Gardens see here')}:{' '}
              <a
                href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens"
                target="_blank"
                rel="noreferrer"
              >
                https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens
              </a>
            </p>
            <img src={jewelerFrame} alt="" />
            <p>
              {t(
                'Another important place to get started is the Jeweler. sJEWEL will go live at launch. JEWEL tokens can be staked with the Jeweler for sJEWEL to earn a portion of in-game transactions. You can purchase JEWEL from the Trader in the Marketplace. This locking mechanism encourages staking into the protocol, and allows those who want to support the project for the long term to have more of a say and receive a larger share of the rewards. More information on sJEWEL can be found here',
              )}{' '}
              <a
                href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-jeweler"
                target="_blank"
                rel="noreferrer"
              >
                https://docs.defikingdoms.com/how-defi-kingdoms-works/the-jeweler
              </a>
            </p>
          </div>
          <div
            className={cx('instructions', 'dfkchain', {
              active: instructionIndex === 1,
            })}
          >
            <h1 className="textCenter">{t('DFK Chain')}</h1>
            <p>
              {t(
                'Good news! For those of you who are already in the game, you already know that Veigar Wavestrider is the one to visit when you need to bridge, and the Crystalvale Docks will host an in-game bridge to Kaia.',
              )}
            </p>
            <p>
              {t(
                'When you head to the Docks, just click on “Travel to Serendale”, and you will be walked through the process to add the Kaia network to your wallet (Coinbase Wallet will not be supported at launch).',
              )}
            </p>
            <p>
              {t(
                'In order to play, you will need KAIA for gas fees. You can use the Token Bridge to send KAIA / JEWEL / etc… to Serendale. The Token Bridge will provide a small amount of KAIA as gas for your first swap. If you are sending something other than KAIA over, head to the Market to buy some KAIA with the Trader.',
              )}
            </p>
            <img src={traderFrame} alt="" />
            <p>
              {t(
                'Hero Bridge, Pet Bridge, Token Bridge, and Item Bridge will all be live on launch day, so you can bring everything you need over from Crystalvale if you so desire! You will also be able to buy and sell JADE, game resources, and other Kaia tokens via Trader Matoya on our new Kaia DEX.',
              )}
            </p>
            <p>
              {t(
                'The Gardens will offer incentivized pools that emit JADE tokens as a reward for providing liquidity. There will be both unlocked and locked JADE rewards, with an initially high emission rate that declines after each epoch. Garden emissions will begin 24 hours after Vesting and Locked JADE airdrops have been completed.',
              )}
            </p>
            <p>{t('The initial pairings available in the Gardens will be as follows')}:</p>
            <ul>
              <li>JADE:JEWEL</li>
              <li>JADE:KAIA</li>
              <li>JADE:AVAX</li>
              <li>JADE:oUSDT</li>
              <li>JADE:oWBTC</li>
              <li>JADE:oETH</li>
              <li>JEWEL:KAIA</li>
              <li>JEWEL:AVAX</li>
              <li>JEWEL:oUSDT</li>
              <li>JEWEL:oWBTC</li>
              <li>JEWEL:oETH</li>
            </ul>
            <img src={jewelerFrame} alt="" />
            <p>
              {t(
                'sJEWEL will go live at launch. JEWEL tokens can be staked with the Jeweler for sJEWEL to earn a portion of in-game transactions. This locking mechanism encourages staking into the protocol, and allows those who want to support the project for the long term to have more of a say and receive a larger share of the rewards.',
              )}
            </p>
          </div>
          <div
            className={cx('instructions', 'harmony', {
              active: instructionIndex === 2,
            })}
          >
            <h1 className="textCenter">{t('Harmony')}</h1>
            <p>
              {t(
                "The time has come for Serendale to launch on Kaia, and if you haven't left Harmony yet, you might be wondering what to do. What remains on Serendale 1.0 can be found on our new Harmony Outpost. There are no bridges from Harmony to Kaia, so if you want to collect and move your assets to the new Serendale, you will need to bridge through Crystalvale. Details on how to do so can be found here",
              )}
              :{' '}
              <a
                href="https://medium.com/defi-kingdoms-official/serendale-1-0-harmony-outpost-455eb67fc46b"
                target="_blank"
                rel="noreferrer"
              >
                https://medium.com/defi-kingdoms-official/serendale-1-0-harmony-outpost-455eb67fc46b
              </a>
            </p>
          </div>
          <div
            className={cx('instructions', 'unknown', {
              active: instructionIndex === 3,
            })}
          >
            <h1 className="textCenter">{t('Everywhere Else')}</h1>
            <h2>{t('How do I get started if I am new to crypto or coming from another chain?')}</h2>
            <p>
              {t(
                "If you are new to the world of cryptocurrency, but you've been hearing a lot about DeFi Kingdoms and want to get started now with this relaunch of Serendale, the first step you will want to take is to go to",
              )}{' '}
              <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                https://game.defikingdoms.com
              </a>
              .
            </p>
            <p>{t('From there, you will be prompted to create a Metamask Wallet.')}</p>
            <p>
              {t(
                'After you have installed your wallet, you will be given the option to visit either Serendale or Crystalvale.',
              )}{' '}
              <Trans
                t={t}
                i18nKey="If you choose to enter Serendale, your wallet will be configured for Kaia (For details on entering Crystalvale, see: <anchor>https://www.defikingdoms.com/crystalvale/</anchor>)."
                components={{
                  anchor: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href="https://www.defikingdoms.com/crystalvale/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </p>
            <p>
              {t(
                "Once you have created your wallet, the game will prompt you to create an account name and select an avatar. You might be surprised to see that you don't have to create any login credentials. This is because your DeFi Kingdoms account is linked to your Metamask. Any time you log in from a browser that has Metamask installed (and you're logged in to your wallet, of course), DeFi Kingdoms will recognize you and load your profile.",
              )}
            </p>
            <p>
              {t(
                "You're now a fully established player of DeFi Kingdoms. There will be plenty to do on launch day. In order to play DeFi Kingdoms, you'll need to own some KAIA tokens. DeFi Kingdoms: Serendale is hosted on the Kaia blockchain, so you'll need these tokens both for gas fees (the transaction fees charged for any blockchain interactions) and to convert into JADE or JEWEL. Head to the Market to buy some KAIA with the Trader.",
              )}
            </p>
            <p>
              {t(
                'To get started with gameplay you might want to head to the Tavern to buy a Hero. For more information on purchasing a Hero see here',
              )}
              :{' '}
              <a
                href="https://docs.defikingdoms.com/gameplay/locations/tavern"
                target="_blank"
                rel="noreferrer"
              >
                https://docs.defikingdoms.com/gameplay/locations/tavern
              </a>
            </p>
            <p>
              {t(
                'Another place to start is the Gardens. The Gardens will offer incentivized pools that emit JADE tokens as a reward for providing liquidity. There will be both unlocked and locked JADE rewards, with an initially high emission rate that declines after each epoch. Garden emissions will begin 24 hours after Vesting and Locked JADE airdrops have been completed.',
              )}
            </p>
            <p>{t('The initial pairings available in the Gardens will be as follows')}:</p>
            <ul>
              <li>JADE:JEWEL</li>
              <li>JADE:KAIA</li>
              <li>JADE:AVAX</li>
              <li>JADE:oUSDT</li>
              <li>JADE:oWBTC</li>
              <li>JADE:oETH</li>
              <li>JEWEL:KAIA</li>
              <li>JEWEL:AVAX</li>
              <li>JEWEL:oUSDT</li>
              <li>JEWEL:oWBTC</li>
              <li>JEWEL:oETH</li>
            </ul>
            <p>
              {t('For more information on the Gardens see here')}:{' '}
              <a
                href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens"
                target="_blank"
                rel="noreferrer"
              >
                https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens
              </a>
            </p>
            <img src={jewelerFrame} alt="" />
            <p>
              {t(
                'Another important place to get started is the Jeweler. sJEWEL will go live at launch. JEWEL tokens can be staked with the Jeweler for sJEWEL to earn a portion of in-game transactions. You can purchase JEWEL from the Trader in the Marketplace. This locking mechanism encourages staking into the protocol, and allows those who want to support the project for the long term to have more of a say and receive a larger share of the rewards. More information on sJEWEL can be found here',
              )}
              :{' '}
              <a
                href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-jeweler"
                target="_blank"
                rel="noreferrer"
              >
                https://docs.defikingdoms.com/how-defi-kingdoms-works/the-jeweler
              </a>
            </p>
          </div>
          <div className="textCenter">
            <a
              href="https://game.defikingdoms.com"
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              {t('Start Playing')}
            </a>
          </div>
        </div>
      </div>

      <div className="tokens">
        <div className="container">
          <div className="token tokenRight tokenCrystal">
            <div className="tokenImg">
              <img src={jadeLogo} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>{t('JADE')}</h1>
              <p>
                {t(
                  'JADE acts as the power token in Serendale, giving it utility above and beyond that of a regular coin. It can be spent to purchase and mint Hero and Pet NFTs, swapped on the in-game DEX, and pooled for liquidity.',
                )}
              </p>
            </div>
          </div>
          <div className="token tokenLeft tokenDFKchain">
            <div className="tokenImg kaia">
              <img src={kaiaIcon} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>{t('Kaia')}</h1>
              <p>
                {t(
                  'Kaia Blockchain is a next-generation Layer 1 blockchain, formed from the merger of Finschia (formerly LINE Blockchain) and Klaytn, backed by tech giants LINE and Kakao. Built on EVM for scalability, reliability, and ease of use, Kaia aims to drive mass blockchain adoption by empowering businesses, individuals, and communities in the Web 3.0 era. With a strong foundation and a commitment to accessibility, Kaia Blockchain serves as a global platform, fostering collaboration, innovation, and new opportunities in the decentralized future.',
                )}{' '}
                <Trans
                  t={t}
                  i18nKey="Find out more at <anchor>https://kaia.io</anchor>."
                  components={{
                    anchor: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a href="https://kaia.io" target="_blank" rel="noreferrer" />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="token tokenRight tokenJewel">
            <div className="tokenImg">
              <img src={jewelToken} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>{t('JEWEL')}</h1>
              <p>
                {t(
                  'JEWEL is used as the Governance and Fee Sharing token for all chains of DeFi Kingdoms by depositing it with the Jeweler. Additionally, JEWEL is used for gas fees on the DFK Chain, where the gameplay transactions for Crystalvale take place.',
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="zones">
        <div className="zoneSlides">
          <Slider {...settings}>
            <div className="slide">
              <div className="slideContent slide1">
                <div className="caption">
                  <h1>{t('Docks')}</h1>
                  {t(
                    '“The Docks” zone is utilized as an in-game interface that taps into bridging services. The Docks is the ongoing zone for all things related to moving tokens on and off chain, including fiat on/off ramps.',
                  )}
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide2">
                <div className="caption">
                  <h1>{t('Marketplace')}</h1>
                  {t(
                    'The Marketplace is the decentralized exchange of DeFi Kingdoms. Here you can purchase and sell tokens at current exchange rates. Other resources that can be earned via Quests are tradable on the Marketplace too.',
                  )}
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide3">
                <div className="caption">
                  <h1>{t('Gardens')}</h1>
                  {t(
                    'In each DeFi Kingdoms realm, the Gardens is a place to stake liquidity pool tokens to receive a share of power token emissions. In the Gardens of Serendale, JADE emissions are on offer for those who stake. You can also become a liquidity provider by interacting with the Druid.',
                  )}
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide4">
                <div className="caption">
                  <h1>{t('Jeweler')}</h1>
                  {t(
                    'The Jeweler accepts “deposits” of JEWEL, becoming sJEWEL, allowing the player to participate in fee distribution and to cast governance votes. More details on how sJEWEL works can be found here',
                  )}
                  {': '}
                  <a
                    href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-jeweler"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://docs.defikingdoms.com/how-defi-kingdoms-works/the-jeweler
                  </a>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide5">
                <div className="caption">
                  <h1>{t('Tavern')}</h1>
                  {t(
                    'The Tavern is the place to visit if you want to purchase or sell Heroes, rent your Hero for Summoning, send a Hero to a friend, or view the Hero Catalog.',
                  )}
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide6">
                <div className="caption">
                  <h1>{t('Portal')}</h1>
                  {t(
                    "The sacred Portal is the location where new Heroes are Summoned from Gaia's Inner Grove.",
                  )}
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide7">
                <div className="caption">
                  <h1>{t('Professions')}</h1>
                  {t(
                    'Heroes can be sent on Profession Quests. Resources found from Profession Quests have various purposes. For example, some fish can be used to produce potions to use in adventure quests. Other things, such as pet eggs, can be used to hatch a pet companion (NFT). Generally, all things can be sold for gold, which can then be used to buy certain items, or swapped through the Trader.',
                  )}
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="zonesBottom textCenter" />
      </div>

      <footer className="textCenter">
        <div className="container">
          <div className="dividerDark"></div>
          <a
            href="https://game.defikingdoms.com"
            className="primary"
            target="_blank"
            rel="noreferrer"
          >
            {t('Start Playing')}
          </a>
          <ul className="social list-unstyled">
            <li className="icon">
              <a
                className="twitter"
                href="https://twitter.com/DefiKingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <span className="sr-only">Twitter</span>
              </a>
            </li>
            <li className="icon">
              <a
                className="reddit"
                href="https://www.reddit.com/r/DefiKingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faReddit} />
                <span className="sr-only">Reddit</span>
              </a>
            </li>
            <li className="icon">
              <a
                className="discord"
                href="https://discord.gg/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} />
                <span className="sr-only">Discord</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://t.me/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTelegram} />
                <span className="sr-only">Telegram</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.youtube.com/c/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
                <span className="sr-only">YouTube</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.instagram.com/defikingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
                <span className="sr-only">Instagram</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.facebook.com/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
                <span className="sr-only">Facebook</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default SerendaleLanding;
