import { faDiscord, faInstagram, faReddit, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import ksLogo from '../img/kscrestemail.png';
import styles from './Footer.module.scss';

const socialLinks = [
  {
    icon: faTwitter,
    label: 'Twitter',
    link: 'https://twitter.com/DefiKingdoms',
  },
  {
    icon: faReddit,
    label: 'Reddit',
    link: 'https://www.reddit.com/r/DefiKingdoms/',
  },
  {
    icon: faDiscord,
    label: 'Discord',
    link: 'https://discord.gg/defikingdoms',
  },
  {
    icon: faTelegram,
    label: 'Telegram',
    link: 'https://t.me/defikingdoms',
  },
  {
    icon: faYoutube,
    label: 'YouTube',
    link: 'https://www.youtube.com/c/defikingdoms',
  },
  {
    icon: faInstagram,
    label: 'Instagram',
    link: 'https://www.instagram.com/defikingdoms/',
  },
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTopRow}>
        <div className={styles.left}>
          <a href="https://kingdomstudios.io/" target="_blank" rel="noreferrer">
            <img src={ksLogo} alt="" />
          </a>
          <div className={styles.linkList}>
            <a href="/news">News</a>
            <a href="https://merch.defikingdoms.com/" target="_blank" rel="noreferrer">
              Merch
            </a>
            <a href="/contact">Contact</a>
            {/* <a href="/terms">Terms of Service</a>
            <a href="/privacy">Privacy Policy</a>
            <a href="/support">Support</a>
            <a href="/press">Press</a> */}
          </div>
        </div>
        <div className={styles.right}>
          <ul className={styles.social}>
            {socialLinks.map((social) => (
              <li className="icon" key={social.label}>
                <a href={social.link} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={social.icon}/>
                  <span className="sr-only">{social.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.footerBottomRow}>
        <p>© {DateTime.now().year} Kingdom Studios, Inc. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
