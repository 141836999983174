import './index.scss';
import lightTheme from './img/light-theme.svg';
import darkTheme from './img/dark-theme.svg';
import cvLogo from './img/dfk_crystalvale_logo.png';
import avaxBlue from './img/avalanche-avax-logo-lightBlue.svg';
import chainLogoBlue from './img/dfk-chain-logo-lightBlue.svg';
import chainLogoDarkBlue from './img/dfk-chain-logo-darkBlue.svg';
import harmonyLogo from './img/harmony-one-logo.svg';
import avaxDarkBlue from './img/avalanche-avax-logo-darkBlue.svg';
import dockmasterMaria from './img/tutorials/dockmasterMaria.gif';
import bankerIan from './img/tutorials/bankerIan.png';
import switchNetwork from './img/tutorials/switchNetwork.png';
import druidUlfur from './img/tutorials/druidUlfur.gif';
import outpost from './img/tutorials/outpost.gif';
import getStarted from './img/tutorials/getStarted.png';
import crystalToken from './img/crystal_token_x4.png';
import jewelToken from './img/jewel_token_x4.png';
import airballoon from './img/Map_Air_Balloon_x2.png';
import ticketReward from './img/ticket_reward_2x.png';
import smallCrystal from './img/small_crystal_2x.png';
import smallJewel from './img/small_jewel_2x.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faFacebook, faInstagram, faReddit, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const settings = {
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
  ],
};

const CrystalvaleLanding = () => {
  const [instructionIndex, setInstructionIndex] = useState(1);
  const [darkMode, setDarkMode] = useState(false);

  return (
    <div className={cx('crystalvaleLanding', { dark: darkMode })}>
      <div className="theme">
        <div className="btn-toggle" onClick={() => setDarkMode(!darkMode)}>
          <img src={lightTheme} className="light-theme" alt="" />
          <img src={darkTheme} className="dark-theme" alt="" />
        </div>
      </div>

      <div className="hero textCenter">
        <div className="logo">
          <img src={cvLogo} alt="" />
        </div>
        <a
          href="https://game.defikingdoms.com"
          className="primary"
          target="_blank"
          rel="noreferrer"
        >
          Start Playing
        </a>
        <div className="tokenLogos">
          <img src={avaxBlue} alt="" />
          <img src={chainLogoBlue} alt="" />
        </div>
      </div>

      <div className="howTo">
        <div className="container">
          <h1 className="textCenter">How to Enter Crystalvale</h1>
          <h2 className="textCenter mobileLeft">
            Where are you coming from? Click on your chain below:
          </h2>
          <div className="choices textCenter">
            <div
              className={cx('choice', 'harmony', { active: instructionIndex === 0 })}
              onClick={() => setInstructionIndex(0)}
            >
              <img src={harmonyLogo} alt="" />
            </div>
            <div
              className={cx('choice', 'avalanche', { active: instructionIndex === 1 })}
              onClick={() => setInstructionIndex(1)}
            >
              <img src={avaxDarkBlue} alt="" />
            </div>
            <div
              className={cx('choice', 'unknown', { active: instructionIndex === 2 })}
              onClick={() => setInstructionIndex(2)}
            >
              ?
            </div>
          </div>
          <div className={cx('instructions', 'harmony', { active: instructionIndex === 0 })}>
            <h1 className="textCenter">Harmony</h1>
            <h2>How do I get from Serendale to Crystalvale?</h2>
            <p>
              If you are currently a DeFi Kingdoms player on Harmony, one of the biggest questions
              you probably have right now is “How do I get started on DFK Chain?”
            </p>
            <img src={dockmasterMaria} className="sharp" alt="" />
            <p>
              Good news! For those of you who are already in the game, you already know that{' '}
              <strong>
                Dockmaster Maria is the one to visit when you need to bridge, and the Serendale
                Docks will host an in-game bridge to DFK Chain
              </strong>
              .
            </p>
            <h2>Step 1: Bridge JEWEL</h2>
            <p>
              JEWEL is used for gas fees on DFK Chain, so{' '}
              <strong>the first thing you will want to do is bridge some JEWEL</strong>. A protocol
              is in place to automatically exchange JEWEL bridged onto the DFK Chain for the native
              gas token. No special action is needed on the part of players to handle this.
            </p>
            <img src={bankerIan} className="sharp" alt="" />
            <h2>Step 2: Travel to Crystalvale</h2>
            <p></p>
            <p>
              Once you have bridged your JEWEL, click on <strong>"Travel to Crystalvale"</strong>{' '}
              and you will be guided to configure your wallet for DFK Chain.
            </p>
            <img src={switchNetwork} alt="" />
            <h2>Step 3 (optional): Stake</h2>
            <p></p>
            <img src={druidUlfur} className="sharp" alt="" />
            <p>
              After you have bridged your assets, you can also travel to Crystalvale through the
              Docks. From there, the process to stake your assets is the same as the process in
              Serendale. Pay a visit to <strong>Druid Ulfur in the Gardens</strong> to pair your
              desired tokens, and then deposit them in the <strong>Ice Gardens</strong>.
            </p>
            <div className="warningFrame">
              <div className="warningFrameEmbellishments">
                <div className="warningFrameContent">
                  Note: It is important to never use all of your JEWEL, as it is needed in order to
                  pay for transactions on the blockchain. Therefore, if you do not have any JEWEL,
                  you can not make any transactions.
                </div>
              </div>
            </div>
          </div>
          <div className={cx('instructions', 'avalanche', { active: instructionIndex === 1 })}>
            <h1 className="textCenter">Avalanche</h1>
            <h2>How do I get started if I'm already an AVAX user?</h2>
            <p>
              If your wallet is already configured for Avalanche, your first stop will be to{' '}
              <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                game.defikingdoms.com
              </a>{' '}
              to enter the Outpost.
            </p>
            <img src={outpost} className="sharp" alt="" />
            <h2>Step 1: Bridge</h2>
            <p>
              At the Outpost you will meet with Snow Sage Ellia. With her you will find a{' '}
              <strong>bridge that allows you to move your AVAX to DFK Chain</strong>. The Outpost
              also has a DEX in case you need to swap other assets unsupported by the bridge, either
              to AVAX or directly to JEWEL. Click on "Get Started" and she will guide you through
              your different options to travel to Crystalvale as well as help you to configure your
              wallet for DFK Chain.
            </p>
            <img src={getStarted} alt="" />
            <h2>Step 2: Swap for more JEWEL</h2>
            <p>
              <strong>Once you bridge, you will receive a small amount of gas for free</strong>, so
              that you can swap your AVAX for JEWEL (at the Marketplace) to use for future gas fees
              as well as to stake for CRYSTAL.
            </p>
            <h2>Step 3 (optional): Pair and Stake</h2>
            <p>
              Pay a visit to <strong>Druid Ulfur in the Gardens</strong> to review the incentivized
              Liquidity Pools, pair your desired tokens, and deposit them in the Ice Gardens to
              start earning CRYSTAL.
            </p>
            <img src={druidUlfur} className="sharp" alt="" />
            <div className="warningFrame">
              <div className="warningFrameEmbellishments">
                <div className="warningFrameContent">
                  Note: It is important to never use all of your JEWEL, as it is needed in order to
                  pay for transactions on the blockchain. Therefore, if you do not have any JEWEL,
                  you can not make any transactions.
                </div>
              </div>
            </div>
          </div>
          <div className={cx('instructions', 'unknown', { active: instructionIndex === 2 })}>
            <h1 className="textCenter">Everywhere Else</h1>
            <h2>How do I get started if I am new to crypto or coming from another chain?</h2>
            <p>
              If you are new to the world of cryptocurrency, but you've been hearing a lot about
              DeFi Kingdoms and this new DFK Chain launch, the first step you will want to take is
              to go to{' '}
              <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                https://game.defikingdoms.com
              </a>
              .
            </p>
            <p>
              From there, you will be prompted to{' '}
              <strong>create either a Coinbase Wallet or Metamask Wallet</strong>.
            </p>
            <p>
              After you have installed your wallet, you will be given the option to visit either
              Serendale or Crystalvale. If you choose to enter Serendale, your wallet will be
              configured for Harmony and allow you to buy ONE.
            </p>
            <p>
              For those who are eager to enter Crystavale, you will be brought to the Outpost so
              that you can buy AVAX. At the Outpost you will meet with Snow Sage Ellia. With her you
              will find a bridge that allows you to move your AVAX to DFK Chain. The Outpost also
              has a DEX in case you need to swap other assets unsupported by the bridge, either to
              AVAX or directly to JEWEL. Click on “Get Started” and she will guide you through your
              different options to travel to Crystalvale as well as help you to configure your
              wallet for DFK Chain.
            </p>
            <img src={getStarted} alt="" />
            <p>
              <strong>Once you bridge, you will receive a small amount of gas for free</strong>, so
              that you can swap your AVAX for JEWEL to use for future gas fees as well as to stake
              for CRYSTAL.
            </p>
            <p>
              Pay a visit to <strong>Druid Ulfur in the Gardens</strong> to review the incentivized
              Liquidity Pools, pair your desired tokens, and deposit them in the Ice Gardens to
              start earning CRYSTAL.
            </p>
            <div className="warningFrame">
              <div className="warningFrameEmbellishments">
                <div className="warningFrameContent">
                  Note: It is important to never use all of your JEWEL, as it is needed in order to
                  pay for transactions on the blockchain. Therefore, if you do not have any JEWEL,
                  you can not make any transactions.
                </div>
              </div>
            </div>
          </div>
          <div className="textCenter">
            <a
              href="https://game.defikingdoms.com"
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              Start Playing
            </a>
          </div>
        </div>
      </div>

      <div className="tokens">
        <div className="container">
          <div className="token tokenLeft tokenAvax">
            <div className="tokenImg">
              <img src={avaxDarkBlue} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>Avalanche</h1>
              <p>
                Avalanche subnet technology provides a way for projects to section off a group of
                Avalanche validators and commission them to validate additional blockchains outside
                of Avalanche’s primary 3 chains (P, X, C chains). By commissioning a subnet, DeFi
                Kingdoms is able to run and manage our own proprietary blockchain.{' '}
                <strong>DFK Chain</strong> combined with Avalanche Subnet infrastructure will unlock
                new potential for DeFi Kingdoms.
              </p>
            </div>
          </div>
          <div className="token tokenRight tokenCrystal">
            <div className="tokenImg">
              <img src={crystalToken} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>CRYSTAL</h1>
              <p>
                CRYSTAL acts as the main token of value in Crystalvale, giving it utility above and
                beyond that of a regular coin. It can be spent to purchase and mint Hero NFTs,
                swapped on the in-game DEX, staked for fee sharing, pooled for liquidity, and
                applied to applicable governance votes.
              </p>
            </div>
          </div>
          <div className="token tokenLeft tokenDFKchain">
            <div className="tokenImg">
              <img src={chainLogoDarkBlue} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>DFK Chain</h1>
              <p>
                DeFi Kingdoms Blockchain is an EVM compatible blockchain that will be validated by
                our subnet and uses the proof of authority / proof of stake consensus algorithms
                developed by Ava Labs. It is our vision that DeFi Kingdoms Blockchain will become a
                go to location for community members and projects to launch GameFi and other
                blockchain gaming experiences in conjunction with our primary offering of DeFi
                Kingdoms: Crystalvale. We are pioneering this Initial Subnet Offering with Ava Labs
                and plan to expand the blockchain metaverse with DeFi Kingdoms as the provider of
                such utility.
              </p>
            </div>
          </div>
          <div className="token tokenRight tokenJewel">
            <div className="tokenImg">
              <img src={jewelToken} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>JEWEL</h1>
              <p>
                When DeFi Kingdoms: Serendale first launched, the JEWEL token launched alongside it,
                playing the same part for Serendale as CRYSTAL does in Crystalvale. Additionally,
                JEWEL is used for gas fees on the DFK Chain, where the gameplay transactions for
                Crystalvale take place.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="zones">
        <div className="zoneSlides">
          <Slider {...settings}>
            <div className="slide">
              <div className="slideContent slide1">
                <div className="caption">
                  <h1>Docks</h1>
                  As already seen in Serendale, DeFi Kingdoms: Crystalvale will utilize the Docks
                  zone as an in-game interface that taps into bridging services. Although the
                  Outpost zones assist in the initial onboarding to DFK Chain, The Docks will be the
                  ongoing zone for all things related to moving tokens on and off chain, including
                  fiat on/off ramps.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide2">
                <div className="caption">
                  <h1>Marketplace</h1>
                  The Marketplace is the decentralized exchange of DeFi Kingdoms. Here you can
                  purchase and sell tokens at current exchange rates. Other resources that can be
                  earned via Quests will be tradable on the Marketplace too.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide3">
                <div className="caption">
                  <h1>Ice Gardens</h1>
                  In each realm, the Gardens is a place to create and stake liquidity pool tokens to
                  receive a share of power token emissions. In the Ice Gardens of Crystalvale,
                  CRYSTAL emissions are on offer for those who stake.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slideContent slide4">
                <div className="caption">
                  <h1>Jeweler</h1>
                  The Jeweler allows players to lock a portion of their power tokens, allowing them
                  to participate in fee sharing, cast governance votes, and take advantage of
                  in-game benefits called PowerUps. Players can deposit or withdraw at any time, in
                  any amount, with no withdrawal fees. When they withdraw, all rewards are withdrawn
                  as well and are fully unlocked and instantly available.
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="zonesBottom textCenter">
          <a
            href="https://game.defikingdoms.com"
            className="primary"
            target="_blank"
            rel="noreferrer"
          >
            Start Playing
          </a>
        </div>
      </div>

      <div id="airdrops" className="airdrops">
        <div className="container">
          <div className="hotairBalloon textCenter">
            <img src={airballoon} alt="" />
          </div>
          <h1 className="textCenter">Crystalvale Airdrop Schedule</h1>
          <div className="airdropGrid">
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Hero Summoners #1</div>
                  <div className="date textCenter">Snapshot: Jan 1 - 21, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Each new Hero that was summoned from Jan 1, 2022 until Jan 21, 2022 will
                      result in 1 raffle entry for the player to win a Shiny Gen 0 Crystalvale Hero
                      Crystal.
                    </p>
                    <p>There will be 10 drawings for a total of 10 Gen 0s.</p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1-hX_vNFxcgnNixdSQnrbknIRmdXuyuI0RCDjp0us7g8/edit?usp=sharing"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Hero Summoners #2</div>
                  <div className="date textCenter">Snapshot: Jan 21 - Feb 11, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Each new Hero that was summoned from Jan 21, 2022 until Feb 11, 2022 will
                      result in 1 raffle entry for the player to win a cache of CRYSTALs and JEWELs.
                      There will be 100 drawings and each winner will receive 100 JEWELs and 100
                      CRYSTALs.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/146DgsCTzjPY5onISRsAc9vszaWAbgKAIyM-j9ckp3og/edit#gid=655091320"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">xJEWEL Holders #1</div>
                  <div className="date textCenter">Snapshot: Jan 3 - 24, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      600k CRYSTAL will be awarded to xJEWEL holders according to their average
                      holdings between Jan 3, 2022 and Jan 24, 2022.
                    </p>
                    <p>
                      In addition, we will be giving away an additional 25 Shiny Crystalvale Gen 0
                      Heroes in a raffle for these xJEWEL holders.
                    </p>
                    <p>You can get xJEWEL by depositing JEWEL at the Jeweler in Serendale.</p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/16687GRzZzWSii8y0DtKrVPMIpHGcfxB6BT3jG8Wx7q8"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">xJEWEL Holders #2</div>
                  <div className="date textCenter">Snapshot: Jan 24 - Feb 14, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={smallJewel} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      600k CRYSTAL and 400k JEWELs to be awarded to xJEWEL holders according to
                      their average holdings between Jan 24, 2022 and Feb 14, 2022.
                    </p>
                    <p>
                      In addition, we will be giving away an additional 25 Shiny Crystalvale Gen 0
                      Heroes in a raffle for these xJEWEL holders. Players will receive 1 entry for
                      100 xJEWELs, and 2 additional entries for every additional 100 xJEWELs.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/16687GRzZzWSii8y0DtKrVPMIpHGcfxB6BT3jG8Wx7q8/edit#gid=341335864"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">
                    Bridged JEWEL and AVAX-JEWEL LP Holders on Avalanche
                  </div>
                  <div className="date textCenter">Snapshot: Jan 3 - 24, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Holders of bridged Avalanche JEWEL and stakers of JEWEL-AVAX Pangolin LP
                      tokens will receive a collective 100k CRYSTAL tokens shortly after the launch
                      of Crystalvale, according to their average Avalanche-bridged JEWEL holdings
                      between Jan 3, 2022 and Jan 24, 2022.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1BO8MY0vANZAx-nMwOW-4mvOrEy8ymXdClZ079f0zRcI"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">JEWEL LP Holders in the Serendale Gardens</div>
                  <div className="date textCenter">Snapshot: Jan 3 - 31, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Staking just became even more rewarding! A collective 300k CRYSTAL tokens will
                      be airdropped to our liquidity providers who have staked in our gardens,
                      according to their average JEWEL staked LP holdings between Jan 3, 2022 and
                      Jan 31, 2022.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1g_0UEqV3nMV1U9GWmdtsi4Sx09hH4h8uMNViedYiJXA"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Hero Owners</div>
                  <div className="date textCenter">Snapshot: Jan 21, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Hero owners rejoice! We will be airdropping CRYSTALs to Hero owners shortly
                      after the launch of Crystalvale according to the following schedule:
                    </p>
                    <ul>
                      <li className="common">Common</li>
                      <li>1 CRYSTAL</li>
                      <li className="uncommon">Uncommon</li>
                      <li>2 CRYSTAL</li>
                      <li className="rare">Rare</li>
                      <li>4 CRYSTAL</li>
                      <li className="legendary">Legendary</li>
                      <li>8 CRYSTAL</li>
                      <li className="mythic">Mythic</li>
                      <li>16 CRYSTAL</li>
                    </ul>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1OkWXejoz0Pdp4RlXjQX6OnaW645AGoqxWbQSLAxA5Wg/edit#gid=0"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Secret Snapshots for xJEWEL Holders #1</div>
                  <div className="date textCenter">Snapshots: Starting Jan 24, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>10 secret snapshots taken over the course of a couple weeks.</p>
                    <p>
                      Prizes from the snapshots include things like gold, items, and raffle entries
                      for eggs or Crystalvale Gen 0 Heroes.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1VZRvQ1twcJ2xb4hHK595esO9Wos4UutZL8ATtV4WCMU/edit#gid=920596975"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Secret Snapshots for xJEWEL Holders #2</div>
                  <div className="date textCenter">Secret Snapshots: Feb 1 - Feb 21, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      We will be taking 7 secret snapshots every few days between Feb 1, 2022 and
                      Feb 21, 2022 to award xJEWEL holders with items, raffle tickets, and more.
                      View the snapshots below to see the prizes. Items and raffle tickets are
                      awarded based on how many xJEWELs are held.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1VZRvQ1twcJ2xb4hHK595esO9Wos4UutZL8ATtV4WCMU/edit#gid=920596975"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Hero Levelers</div>
                  <div className="date textCenter">Snapshot: Feb 14, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={smallJewel} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      5 Shiny Gen 0s will be given to the first 5 wallets with a Hero that reaches
                      level 10 at a snapshot point on Feb 14, 2022. We will be giving 100 CRYSTALS
                      and 100 JEWELs to the next 5 runner ups, and 50 to the following 10.
                    </p>
                    <p>
                      We will also be awarding 1 CRYSTAL AND 1 JEWEL to players for each Hero level
                      above 1 amongst heroes that they own. So if you have a level 3 Hero and 4
                      level 2 Heroes, you would get 2 + 4 = 6 CRYSTALs and JEWELs.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1G6h7rmJjCQMB9ka60U7rqr-jnpn4A3YYgTIy2BwIgnw/edit#gid=0"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Level 10 Heroes</div>
                  <div className="date textCenter">Snapshot: Feb 21, 2022</div>
                  <div className="airdropStatus textCenter yes">Aidropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      All heroes who get to level 10 before Feb 21, 2022, will be entered into a
                      raffle to win 10 Crystalvale Gen 0s. The 5 Gen 0 winners from the Feb 14, 2022
                      snapshot will not be eligible for this, as they have already won.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/13vfMyZ5R6kpH1HKVu0PZc9SHRRHeRzJjsyZx-qEhkWk/edit?usp=sharing"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Perilous Journey</div>
                  <div className="date textCenter">Snapshot: Mar 16, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={smallJewel} alt="" />
                    </div>
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      JEWEL, CRYSTAL, and Crystalvale Gen 0 Hero raffle tickets are earned through
                      sending your Heroes on the Perilous Journey.
                    </p>
                    <p>
                      Rewards are decided depending on if the Hero survived or perished, along with
                      many other factors.
                    </p>
                    <p>
                      100 Crystalvale Gen 0 Heroes will be raffled to those with Heroes who died and
                      100 additional Crystalvale Gen 0 Heroes will be raffled to those with Heroes
                      who survived.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1_pgkVelMsyWW7PuZ11sSewh7XV47q3lCZ6QxXgbQDPA/edit#gid=318539519"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">Crystalvale Ice Gardens</div>
                  <div className="date textCenter">Snapshot: Mar 31, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={smallCrystal} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      Anyone who has staked liquidity in the JEWEL/xJEWEL Crystalvale ice garden by
                      the time emissions began on Mar 31, 10am EDT, qualified for this CRYSTAL
                      airdrop.
                    </p>
                    <p>
                      This specific airdropped CRYSTAL will be partially locked, with 5% unlocked at
                      the time of the airdrop and the remaining 95% unlocking linearly over one
                      year. As such these particular locked CRYSTALs unlock a full year earlier than
                      most other locked CRYSTALs from this epoch. In preparation for this, we have
                      minted these CRYSTALs and stored them in a multisig, ready for airdrop.
                    </p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/184fhoKCTYpiGA-nAS0a-f0dolsgDFlO5NY0pr4A-6nM/"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
            <div className="airdropContainer">
              <div className="airdropContent">
                <div className="airdropTop">
                  <div className="title textCenter">xCRYSTAL Holders</div>
                  <div className="date textCenter">Snapshot: Apr 4 - 25, 2022</div>
                  <div className="airdropStatus textCenter yes">Airdropped</div>
                  <div className="rewards">
                    <div className="rewardBox">
                      <img src={ticketReward} alt="" />
                    </div>
                  </div>
                  <div className="description">
                    <p>
                      An xCRYSTAL snapshot will take place once every day between Apr 4, 2022 and
                      ends at 11:59pm EST on Apr 25, 2022. If you have an average balance of 10
                      xCRYSTAL within those 21 days, you will be entered into this Crystalvale Gen 0
                      Hero raffle.
                    </p>
                    <p>
                      Each wallet can only win once and the entry calculation will be an average
                      over the time period.
                    </p>
                    <p>
                      Each 10 xCRYSTAL will earn 1 ticket for the raffle. There will be a multiple
                      applied for milestones, adding 0.1x to each 100 xCRYSTAL. So at 100 xCRYSTAL,
                      tickets awarded would be 10 x 1.1 or 11 tickets, 200 xCRYSTAL gets 24 tickets,
                      300 xCRYSTAL gets 39 tickets... and so on.
                    </p>
                    <p>100 Crystalvale Gen 0s will be given away in this raffle.</p>
                  </div>
                </div>
                <div className="airdropButtons">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1L-g0TnLufgPp3wTDeBRXdrnaAhsbNbjKZEKHPthMG4w/"
                    className="viewSnapshot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Snapshot
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="textCenter">
        <div className="container">
          <div className="dividerDark"></div>
          <a
            href="https://game.defikingdoms.com"
            className="primary"
            target="_blank"
            rel="noreferrer"
          >
            Start Playing
          </a>
          <ul className="social list-unstyled">
            <li className="icon">
              <a
                href="https://twitter.com/DefiKingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <span className="sr-only">Twitter</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.reddit.com/r/DefiKingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faReddit} />
                <span className="sr-only">Reddit</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://discord.gg/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} />
                <span className="sr-only">Discord</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://t.me/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTelegram} />
                <span className="sr-only">Telegram</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.youtube.com/c/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
                <span className="sr-only">YouTube</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.instagram.com/defikingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
                <span className="sr-only">Instagram</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.facebook.com/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
                <span className="sr-only">Facebook</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default CrystalvaleLanding;
