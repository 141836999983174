import banner from './images/exploration-banner.png';
import subbanner from './images/adventuring-subbanner.png';
import badMotherclucker from './images/bad-motherclucker.png';
import madBoar from './images/mad-boar.png';
import patrols from './images/patrols.png';
import styles from './index.module.scss';
import Header from '../../components/Header';
import { Hero } from '../SubPage/Hero';
import CoreAccordion from '../../components/CoreAccordion';
import { MainCopySection } from '../SubPage/MainCopySection';
import { RightCopySection } from '../SubPage/RightCopySection';
import { LeftCopySection } from '../SubPage/LeftCopySection';
import { CenterCopySection } from '../SubPage/CenterCopySection';
import { NextCopySection } from '../SubPage/NextCopySection';
import Footer from '../../components/Footer';

function AdventuringPage() {
  return (
    <>
      <Header />
      <main className={styles.subpage}>
        <Hero text="Adventuring" subText="Prepare for epic battles, discover hidden treasures, and become the legendary commander you were destined to be." image={banner} />
        <CoreAccordion subpageIndex={2} isSubpage />
        <MainCopySection text="Take a horse, a boat, or an airship from the capital city to the hinterlands in search of new opportunities and rewards! They’re right behind the big monsters over there." subText="Search for Adventure" />
        <RightCopySection image={madBoar} header="Mad Boar" copy={
          <>
            <p>Mad Boar is a Void Hunt that is a PvE combat experiences undertaken with three Heroes. Send your Combat Party into the wilderness to defeat epic beasts and earn XP, Void Shards, Gaia’s Tears, or rare equipment drops. Defend the realm, join the Hunt today!
            </p><p>There have been reports of a mama boar and her piglets infected with manshroom spores terrorizing villages in the snowy cliffs above Reyalin Mountain Pass. We are calling on all able-bodied Heroes to go investigate.</p>
          </>
        } />
        <LeftCopySection className={styles.leftSection} image={badMotherclucker} header="Bad Motherclucker" copy={<>
          <p>Another Void Hunt for Heroes to answer the call, best suited for Level 12 and up to take on the challenge. It introduces seven additional equipment items! These items include new weapons and weapon types, armor, and shields!</p>
          <p>Be warned, these are vicious birds and will not hesitate to defend themselves. Only the strongest and best equipped Heroes will stand a chance against these pesky rocbocs!</p>
        </>} />
        <RightCopySection image={patrols} header="Patrols" copy={
          <>
            <p>Here is where Heroes Patrol the Colosseum, keeping the citizens of Submersia safe from rogues and criminals! The city greatly appreciates this service, and rewards those Heroes with XP, Gold, and the opportunity for unique loot drops!</p>
            <p>Get out there and keep the streets safe!</p>
            <a className={styles.link} href='https://game.defikingdoms.com/?isPatrolsDemo=true' target="_blank" rel='noreferrer'>Try For Free ▸</a>
          </>
        } />
        <CenterCopySection image={subbanner} header="Limited-Time Events" copy={
          <p>
            On the field of battle, you never know what hurdles you may face. Be ready for
            unexpected monster encounters, intense tournaments, and competition over finite
            resources. These time-limited events require shrewd positioning of your Heroes across
            realms, putting your skills to the test. Stay vigilant, be ready for anything, and seize
            the moment to claim your legacy!
          </p>
        } />
        <NextCopySection label="World Exploration" link="exploration" />
        <CoreAccordion subpageIndex={2} isSubpage />
      </main>
      <Footer />
    </>
  );
}

export default AdventuringPage;
