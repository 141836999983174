import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faFacebook, faInstagram, faReddit, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './index.scss';
import logo from '../../img/DFK-logo.png';

const Social = () => {
  return (
    <>
      <div className="socialContainer">
        <div className="tutorialOverlay">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <div className="fancyModal show socialList">
            <div className="tutorialContent">
              <div className="fancyTitle">Socials</div>

              <div className="tutorial">
                <ul className="social list-unstyled">
                  <li className="icon">
                    <a
                      href="https://twitter.com/DefiKingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                      &nbsp;Twitter
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://www.reddit.com/r/DefiKingdoms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faReddit} />
                      &nbsp;Reddit
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/defikingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/defikingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://www.youtube.com/c/defikingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                      &nbsp;Youtube
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://www.instagram.com/defikingdoms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                      &nbsp;Instagram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://www.facebook.com/defikingdomsofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                      &nbsp;Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="fancyModal show socialList" style={{ marginTop: 20 }}>
            <div className="tutorialContent">
              <div className="fancyTitle">Intl. Discords</div>

              <div className="tutorial">
                <ul className="social list-unstyled">
                  <li className="icon">
                    <a
                      href="https://discord.gg/NX8ajTSsTA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Japanese Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/dfk-chinese"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Chinese Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/j4sSkCdnWt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Russian Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/Fh3H7CMdbS"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Italian Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/7GK9TPQvFS"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Filipino Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/nZD5aDhAw2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Spanish Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://discord.gg/wfGUqtmdMT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faDiscord} />
                      &nbsp;Malaysian / Singaporean Discord
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="fancyModal show socialList" style={{ marginTop: 20 }}>
            <div className="tutorialContent">
              <div className="fancyTitle">Intl. Telegrams</div>

              <div className="tutorial">
                <ul className="social list-unstyled">
                  <li className="icon">
                    <a
                      href="https://t.me/DefiKingdomsChinese"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Chinese Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/DeFiKingdomsBrazil"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Portuguese Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/DeFiKingdomsEsp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Spanish Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/DefiKingdoms_IT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Italian Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/DeFiKingdomsID"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Indonesian Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/DeFiKingdomsPH"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Filipino Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      href="https://t.me/DefiKingdomsRu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTelegram} />
                      &nbsp;Russian Telegram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
