import dfkDuelLogo from '../img/pvp/dfk-duel.png';
import duelScreenshot from '../img/pvp/duel-screenshot.png';
import styles from './AdventuringSection.module.scss';
import patrolsGameplay from '../img/adventuring/patrols-gameplay.png';
import patrolsLogo from '../img/adventuring/patrols-logo.png';
import colosseumGameplay from '../img/adventuring/colosseum-gameplay.png';
import colosseumLogo from '../img/adventuring/colosseum-logo.png';
import LearnMoreLink from '../../../components/LearnMoreLink';
import ScrollOffset from '../../../components/ScrollOffset';

function AdventuringSection() {
  return (
    <section className={styles.adventuring}>
      <ScrollOffset id="adventuring" />
      <div className={styles.gamefiDetailsRow}>
        <div className={styles.columnRight}>
          <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
            <img src={patrolsLogo} alt="" />
            <h3>Adventuring</h3>
            <h4>Patrols</h4>
            <p>Prove you’re up to the task and test your abilities in choose-your-own-adventure, progressive-style system.</p>
            <LearnMoreLink link="/adventuring" />
          </div>
        </div>
        <div className={styles.columnLeft}>
          <img src={patrolsGameplay} alt="" />
        </div>
      </div>
      <div className={styles.gamefiDetailsRow}>
      <ScrollOffset id="pvp" />
        <div className={styles.columnLeft}>
          <img src={colosseumGameplay} alt="" />
        </div>
        <div className={styles.columnRight}>
          <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
            <img src={colosseumLogo} alt="" />
            <h3>Player vs Player</h3>
            <h4>Tournaments</h4>
            <p>DeFi Kingdoms brings competitive gameplay to the forefront of the Colosseum with their new PvP Tournaments feature with over $75,000 in cash-equivalent prizes</p>
            <LearnMoreLink link="/pvp" />
          </div>
        </div>
      </div>
      <div className={styles.gamefiDetailsRow}>
        <div className={styles.columnRight}>
          <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
            <img src={dfkDuelLogo} alt="" />
            <h3>Player vs Player</h3>
            <h4>DFK DUEL</h4>
            <p>
              Battle it out with other players and rise through the ranks to become a legendary
              champion.
            </p>
            <LearnMoreLink link="/pvp" />
          </div>
        </div>
        <div className={styles.columnLeft}>
          <img src={duelScreenshot} alt="" />
        </div>
      </div>
    </section>
  );
}

export default AdventuringSection;
