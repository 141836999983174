import cx from 'classnames'
import { ReactNode } from 'react';
import styles from './RightCopySection.module.scss'
import { SectionHeader } from './SectionHeader';

type RightCopySectionProps = { className?: string; image: string; header: string; copy: ReactNode; }

export function RightCopySection({ className, image, header, copy }: RightCopySectionProps) {
  return (
    <section className={cx(styles.rightSection, className)}>
      <div className={styles.leftColumn}>
        <img src={image} alt="" />
      </div>
      <div className={styles.rightColumn}>
        <SectionHeader text={header} />
        <div className={styles.rightCopy}>{copy}</div>
      </div>
    </section>
  )
}
