import cx from 'classnames'
import banner from './images/pvp-banner.png';
import colosseumBouts from './images/colosseum-bouts.png';
import colosseumTournaments from './images/colosseum-tournaments.png';
import subbanner from './images/esports.png';
import duelScreen from './images/duel-screen.png';
import ladderScreen from './images/ladder-screen.png';
import styles from './index.module.scss';
import Header from '../../components/Header';
import CoreAccordion from '../../components/CoreAccordion';
import Footer from '../../components/Footer';
import { CenterCopySection } from '../SubPage/CenterCopySection';
import { LeftCopySection } from '../SubPage/LeftCopySection';
import { RightCopySection } from '../SubPage/RightCopySection';
import { MainCopySection } from '../SubPage/MainCopySection';
import { Hero } from '../SubPage/Hero';
import { NextCopySection } from '../SubPage/NextCopySection';

function PvpPage() {
  return (
    <>
      <Header />
      <main className={styles.subpage}>
        <Hero text="Player vs Player" subText="Test your mettle against players, rise through the ranks to become a legendary PvP champion, leaving your mark on the vibrant and competitive community" image={banner} />
        <CoreAccordion subpageIndex={1} isSubpage />
        <MainCopySection text="Party Synergy" subText={
          <>
            <p>
              Command your Heroes on the battlefield and emerge victorious! With countless
              possibilities, building a cohesive team and unlocking their collective potential will
              position you for success.
            </p>
            <p>
              Whether you are the aggressor or take a defensive stance, only one thing matters:
              defeating your opponent.
            </p>
          </>}
        />
        <RightCopySection image={colosseumTournaments} header="DFK COLOSSEUM: TOURNAMENTS" copy={
          <>
            <p>Launched in February 26th, a 6-month series of tournaments began, where players face off in progressively more challenging environments, culminating in an All-Star Tournament to showcase the best players in the Kingdoms.</p>
            <p>Over $75,000 in cash-equivalent prizes will be rewarded throughout this tournament series, plus exclusive NFTs and other prizes!</p>
            <p>There are also several ways to get involved in tournaments, including spectating and voting on winners to earn rewards!</p>
          </>}
        />
        <LeftCopySection className={styles.leftSection} image={colosseumBouts} header="DFK COLOSSEUM: SINGLE BOUTS" copy={
          <>
            <p>PvP mode is hosted in an exclusive region called the “Sundered Isles” on the Metis blockchain. PvP Bouts allow you to select three Heroes to challenge another player for glory and potential rewards.</p>
            <p>Get your Heroes, skills and potion loadouts ready and join the battle!</p>
          </>}
        />
        <RightCopySection image={duelScreen} header="DFK Duel" copy={
          <>
            <p>
              Unleash your Heroes in this TCG-style player vs player minigame. Engage and face off
              against opponents based on randomly selected Hero stats.
            </p>
            <p>
              But stay vigilant, as rotating bonuses can tip the scales between emerging triumphant
              and facing defeat. Embrace the thrill of chance while wielding the power of
              player-driven decisions.
            </p>
          </>}
        />
        <LeftCopySection className={styles.leftSection} image={ladderScreen} header="Ladder Play & Tournaments" copy={
          <>
            <p>
              Arise as the champion among champions! Ascend the ranks in global ladder play or enter
              as a new challenger in regional tournaments.
            </p>
            <p>
              Compete against fellow players in 3v3 Hero Combat or in DFK Duels, testing your mettle
              and acumen to claim victory and etch your name in the annals of greatness.
            </p>
          </>}
        />
        <CenterCopySection image={subbanner} header="eSports" copy={
          <>
            <p>
              Free your competitive spirit on the grand stage! With our replayable and streamable
              combat system, success is even sweeter when others are watching. Share your strategies,
              showcase your triumphs, and revel in the exhilaration of eSports glory!
            </p>
          </>
        } />
        <NextCopySection label="Adventuring" link="adventuring" />
        <CoreAccordion subpageIndex={1} isSubpage />
      </main>
      <Footer />
      <button className={cx(styles.backArrow)} onClick={() => (window.location.href = '/')}>
        <span>&#8962;</span>
      </button>
    </>
  );
}

export default PvpPage;
