import './index.scss';
import siLogo from './img/si-logo.png';
import metisLogo from './img/metis-logo.png';
import metisLogoBrown from './img/metis-logo-brown.png';
import dfkchainLogo from './img/dfkchain-logo-brown.png';
import jewelToken from './img/jewel_token_x4.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faFacebook, faInstagram, faReddit, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const settings = {
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
  ],
};

const SunderedIslesLanding = () => {
  const [instructionIndex, setInstructionIndex] = useState(0);

  return (
    <div className='sunderedIslesLanding'>
      <div className="hero textCenter">
        <div className="logo">
          <img src={siLogo} alt="" />
        </div>
        <a
          href="https://game.defikingdoms.com"
          className="primary"
          target="_blank"
          rel="noreferrer"
        >
          Start Playing
        </a>
      </div>

      <div className="howTo">
        <div className="container">
          <h1 className="textCenter">How to Enter Sundered Isles</h1>
          <h2 className="textCenter mobileLeft">
            Where are you coming from? Click on your chain below:
          </h2>
          <div className="choices textCenter">
            <div
              className={cx('choice', 'metis', { active: instructionIndex === 0 })}
              onClick={() => setInstructionIndex(0)}
            >
              <img src={metisLogoBrown} alt="" />
            </div>
            <div
              className={cx('choice', 'dfkchain', { active: instructionIndex === 1 })}
              onClick={() => setInstructionIndex(1)}
            >
              <img src={dfkchainLogo} alt="" />
            </div>
            <div
              className={cx('choice', 'unknown', { active: instructionIndex === 2 })}
              onClick={() => setInstructionIndex(2)}
            >
              ?
            </div>
          </div>
          <div className={cx('instructions', 'harmony', { active: instructionIndex === 0 })}>
            <h1 className="textCenter">Metis</h1>
            <p>If your wallet is already configured for Metis, your first stop will be to https://game.defikingdoms.com.</p>
            <p>If this is your first time playing, you’ll just click “Start Playing” and the game will prompt you to create an account name and select an avatar.</p>
            <p>You might be surprised to see that you don’t have to create any login credentials. This is because your DeFi Kingdoms account is linked to your wallet. Any time you log in from a browser that has Metamask installed (and you’re logged in to your wallet, of course), DeFi Kingdoms will recognize you and load your profile.</p>
          </div>
          <div className={cx('instructions', 'dfkchain', { active: instructionIndex === 1 })}>
            <h1 className="textCenter">DFK Chain</h1>
            <p>Good news! For those of you who are already in the game, you already know that Veigar Wavestrider is the one to visit when you need to bridge, and the Crystalvale Docks will serve all your bridging needs in-game.</p>
            <p>Once you’ve shipped off any assets, click on the drop-down menu in the bottom left that currently says “Crystalvale.” Select the Sundered Isles realm and the Metis network will be automatically added to your wallet and you’ll arrive in the Sundered Isles.</p>
            <p>In order to play, you will need METIS for gas fees. You can use the Token Bridge to send JEWEL to the Sundered Isles. The Token Bridge will provide a small amount of METIS as gas for your first swap. You will then want to visit our partner DEX Hercules, which can be found at the Onboarding NPC in the Sundered Isles Docks zone in order to trade for more METIS.</p>
            <p>Hero Bridge, Pet Bridge, Token Bridge, and Item Bridge are all available, so you can bring everything you need over from Crystalvale if you so desire! You are also able to buy and sell game resources and other tokens at the Bazaar.</p>
          </div>
          <div className={cx('instructions', 'unknown', { active: instructionIndex === 2 })}>
            <h1 className="textCenter">Everywhere Else</h1>
            <h2>How do I get started if I am new to crypto or coming from another chain?</h2>
            <p>
              If you are new to the world of cryptocurrency, but you've been hearing a lot about
              DeFi Kingdoms and this new DFK Chain launch, the first step you will want to take is
              to go to{' '}
              <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
                https://game.defikingdoms.com
              </a>
              .
            </p>
            <p>If you are new to the world of cryptocurrency, but you've been hearing a lot about DeFi Kingdoms and the DFK Colosseum, the first step you will want to take is to go to https://game.defikingdoms.com. From there, you will be prompted to create a Metamask Wallet. After you have installed your wallet, you will be given the option to visit either Serendale, Crystalvale, or Sundered Isles.</p>
            <p>Enter Sundered Isles and your wallet will be configured for Metis and allow you to buy METIS. by visiting the Onboarding NPC at the Docks.</p>
          </div>
        </div>
      </div>

      <div className="tokens">
        <div className="container">
          <div className="token tokenRight tokenAvax">
            <div className="tokenImg">
              <img src={metisLogo} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>Metis</h1>
              <p>Metis is a next-generation Layer 2 blockchain designed for scalability, security, and decentralization. It enhances Ethereum’s capabilities by offering fast, low-cost transactions while maintaining full compatibility with the Ethereum Virtual Machine (EVM). Officially launched in 2021, Metis has grown into a leading Layer 2 ecosystem, empowering developers, businesses, and DAOs with innovative on-chain solutions. Metis is pioneering a new era of Web3 adoption with a focus on scaling real-world applications. The network continues to attract projects across DeFi, gaming, and enterprise blockchain solutions. Find out more at https://www.metis.io.</p>
            </div>
          </div>
          <div className="token tokenLeft tokenJewel">
            <div className="tokenImg">
              <img src={jewelToken} alt="" />
            </div>
            <div className="tokenDescription">
              <h1>JEWEL</h1>
              <p>JEWEL is used as the Governance and Fee Sharing token for all chains of DeFi Kingdoms by depositing it with the Jeweler. Further description of how JEWEL is used in Sundered Isles.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="zones">
        <div>
          <div className="zoneSlides">
            <Slider {...settings}>
              <div className="slide">
                <div className="slideContent slide1">
                  <div className="caption">
                    <h1>Docks</h1>
                    The "Docks" zone is utilized as an in-game interface for everything players need to get started playing or keep building their legion. The Docks is an ongoing zone for purchasing and leveling up Heroes, trading tokens, and moving tokens on and off chain, including fiat on ramps.
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slideContent slide2">
                  <div className="caption">
                    <h1>Registry</h1>
                    Battle-ready Heroes line up at various NPCs in the Registry zone, your central hub for managing Hero activities and engaging in battles, tournaments, and rewards. Whether you’re competing in the DFK Colosseum, pledging Heroes at the Recruiter, sending them on Patrols, or collecting rewards with the Shell Collector, the Registry Zone is where you make strategic decisions that shape your Hero legion!
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slideContent slide3">
                  <div className="caption">
                    <h1>DFK Colosseum</h1>
                    Assemble a team of three Heroes to join tournaments and battle other players for glory and rewards or just challenge your friends for fun.
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slideContent slide4">
                  <div className="caption">
                    <h1>Recruiter</h1>
                    Pledge your Heroes NFTs as Bannermen to Submersia, earning Influence that grants $JEWEL rewards and passive experience. Influence can also be used to vote on PvP Bout outcomes, where correct votes earn a share of sponsorship rewards.
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slideContent slide5">
                  <div className="caption">
                    <h1>Patrols</h1>
                    An entry-level combat feature designed to make combat accessible to all players! This new mode allows players to engage in battles without the need for high-level Heroes or advanced strategies, providing an inclusive experience for all gamers.
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="slideContent slide6">
                  <div className="caption">
                    <h1>Shell Collector</h1>
                    An exciting daily activity reward system that encourages players to engage in various in-game tasks! By collecting Shells, enhance the gaming experience and unlock unique rewards.
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="preFooter">
        <div className='overlay' />
      </div>
      <footer className="textCenter">
        <div className="container">
          <a
            href="https://game.defikingdoms.com"
            className="primary"
            target="_blank"
            rel="noreferrer"
          >
            Start Playing
          </a>
          <ul className="social list-unstyled">
            <li className="icon">
              <a
                href="https://twitter.com/DefiKingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <span className="sr-only">Twitter</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.reddit.com/r/DefiKingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faReddit} />
                <span className="sr-only">Reddit</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://discord.gg/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} />
                <span className="sr-only">Discord</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://t.me/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTelegram} />
                <span className="sr-only">Telegram</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.youtube.com/c/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
                <span className="sr-only">YouTube</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.instagram.com/defikingdoms/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
                <span className="sr-only">Instagram</span>
              </a>
            </li>
            <li className="icon">
              <a
                href="https://www.facebook.com/defikingdoms"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
                <span className="sr-only">Facebook</span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default SunderedIslesLanding;
