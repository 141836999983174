import styles from './Hero.module.scss'

type HeroProps = { text: string; subText?: string; image: string }

export function Hero({ text, subText, image }: HeroProps) {
  return (
    <section
      className={styles.hero}
      style={{
        background: `url(${image}) center center / cover no-repeat`,
      }}
    >
      <h2>{text}</h2>
      {subText && <p>{subText}</p>}
    </section>
  )
}
