import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import BugReport from './pages/BugReport';
import CrystalvaleTour from './pages/CrystalvaleTour';
import News from './pages/News';
import Social from './pages/Social';
import CrystalvaleLanding from './pages/CrystalvaleLanding';
import SerendaleLanding from './pages/SerendaleLanding';
import SunderedIslesLanding from './pages/SunderedIslesLanding';
import CommandHeroesPage from './pages/CommandHeroesPage';
import PvpPage from './pages/PvpPage';
import AdventuringPage from './pages/AdventuringPage';
import ExplorationPage from './pages/ExplorationPage';
import GatheringPage from './pages/GatheringPage';
import Web3ActivationPage from './pages/Web3ActivationPage';

const App = () => {
  return (
    <BrowserRouter>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="commandheroes" element={<CommandHeroesPage />} />
          <Route path="pvp" element={<PvpPage />} />
          <Route path="adventuring" element={<AdventuringPage />} />
          <Route path="exploration" element={<ExplorationPage />} />
          <Route path="gatheringcrafting" element={<GatheringPage />} />
          <Route path="web3" element={<Web3ActivationPage />} />
          <Route path="bugreport" element={<BugReport />} />
          <Route path="bugreport.html" element={<BugReport />} />
          <Route path="contact" element={<Contact />} />
          <Route path="contact.html" element={<Contact />} />
          <Route path="crystalvaletour" element={<CrystalvaleTour />} />
          <Route path="crystalvaletour.html" element={<CrystalvaleTour />} />
          <Route path="crystalvale" element={<CrystalvaleLanding />} />
          <Route path="crystalvale.html" element={<CrystalvaleLanding />} />
          <Route path="news" element={<News />} />
          <Route path="news.html" element={<News />} />
          <Route path="serendale" element={<SerendaleLanding />} />
          <Route path="sundered-isles" element={<SunderedIslesLanding />} />
          <Route path="social" element={<Social />} />
          <Route path="social.html" element={<Social />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
