import { ReactNode } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import styles from './index.module.scss';
import cx from 'classnames';
import CoreAccordion from '../../components/CoreAccordion';
import { Hero } from './Hero';
import { MainCopySection } from './MainCopySection';
import { LeftCopySection } from './LeftCopySection';
import { RightCopySection } from './RightCopySection';
import { CenterCopySection } from './CenterCopySection';
import { NextCopySection } from './NextCopySection';

interface SubPageProps {
  children?: ReactNode;
  heroHeader: string;
  heroSubheader: string;
  heroBackground: string;
  mainCopyHeader: string;
  mainCopySubheader: ReactNode;
  rightSectionImage: string;
  rightSectionHeader: string;
  rightSectionCopy: ReactNode;
  leftSectionImage: string;
  leftSectionHeader: string;
  leftSectionCopy: ReactNode;
  centerSectionImage: string;
  centerSectionHeader: string;
  centerSectionCopy: ReactNode;
  leftClassName?: string;
  rightClassName?: string;
  nextSectionLabel: string;
  nextSectionLink: string;
  subpageIndex: number;
}

function SubPage({
  children,
  heroHeader,
  heroBackground,
  heroSubheader,
  mainCopyHeader,
  mainCopySubheader,
  rightSectionHeader,
  rightSectionCopy,
  rightSectionImage,
  leftSectionHeader,
  leftSectionCopy,
  leftSectionImage,
  centerSectionHeader,
  centerSectionCopy,
  centerSectionImage,
  leftClassName,
  rightClassName,
  nextSectionLabel,
  nextSectionLink,
  subpageIndex,
}: SubPageProps) {
  return (
    <>
      <Header />
      <main className={styles.subpage}>
        {/* Hero Section */}
        <Hero text={heroHeader} subText={heroSubheader} image={heroBackground} />
        <CoreAccordion subpageIndex={subpageIndex} isSubpage />
        {/* Custom Section */}
        {children}
        {/* Main Copy */}
        <MainCopySection text={mainCopyHeader} subText={mainCopySubheader} />
        {/* Right Section */}
        <RightCopySection className={rightClassName} image={rightSectionImage} header={rightSectionHeader} copy={rightSectionCopy} />
        {/* Left Section */}
        <LeftCopySection className={leftClassName} image={leftSectionImage} header={leftSectionHeader} copy={leftSectionCopy} />
        {/* Center Section */}
        <CenterCopySection image={centerSectionImage} header={centerSectionHeader} copy={centerSectionCopy} />
        {/* Next Section */}
        <NextCopySection label={nextSectionLabel} link={nextSectionLink} />
        <CoreAccordion subpageIndex={subpageIndex} isSubpage />
      </main>
      <Footer />
      <button className={cx(styles.backArrow)} onClick={() => (window.location.href = '/')}>
        <span>&#8962;</span>
      </button>
    </>
  );
}

export default SubPage;
