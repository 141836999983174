import styles from './RoadmapSection.module.scss';

function RoadmapSection() {
  return (
    <section className={styles.roadmap}>
      <div className={styles.container}>
        <h2>Roadmap</h2>
        <p>Since DeFi Kingdoms launched in August 2021:</p>
        <div className={styles.accomplishments}>
          <a href="https://www.youtube.com/@DeFiKingdoms" target="_blank" rel='noreferrer'>
            <div className={styles.success}>
              <p className={styles.number}>125+</p>
              <p className={styles.item}>Developer AMAs</p>
            </div>
          </a>
          <a href="https://vote.defikingdoms.com/#/" target="_blank" rel='noreferrer'>
            <div className={styles.success}>
              <p className={styles.number}>16+</p>
              <p className={styles.item}>Governance Proposals</p>
            </div>
          </a>
          <div className={styles.success}>
            <p className={styles.number}>40+</p>
            <p className={styles.item}>Feature Releases</p>
          </div>
          <div className={styles.success}>
            <p className={styles.number}>1,950,000+</p>
            <p className={styles.item}>Total Heroes</p>
          </div>
        </div>
        <p>
          Check out how DeFi Kingdoms will continue to grow with new features, systems, maps, and
          cross-chain gameplay!
        </p>
        <a
          className={styles.externalButton}
          href="https://docs.defikingdoms.com/roadmap"
          target="_blank"
          rel="noreferrer"
        >
          View Full Roadmap
        </a>
      </div>
    </section>
  );
}

export default RoadmapSection;
