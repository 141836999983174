import { ReactNode } from 'react'
import styles from './MainCopySection.module.scss'

type MainCopyProps = { text: string, subText: ReactNode }

export function MainCopySection({ text, subText }: MainCopyProps) {
  return (
    <section className={styles.mainCopy}>
      <div className={styles.copy}>
        <h3>{text}</h3>
        {subText && <p>{subText}</p>}
      </div>
    </section>
  )
}
