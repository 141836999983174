import { ReactNode } from "react"
import styles from './CenterCopySection.module.scss'

type CenterCopySectionProps = { image: string, header: string, copy: ReactNode }

export function CenterCopySection({ image, header, copy }: CenterCopySectionProps) {
  return (
    <section
      className={styles.centerSection}
      style={{
        background: `url(${image}) center center / cover no-repeat`,
      }}
    >
      <div className={styles.copy}>
        <h2>{header}</h2>
        <p>{copy}</p>
        <a href="https://game.defikingdoms.com" className={styles.playButton}>
          Play Now
        </a>
      </div>
    </section>
  )
}
