import styles from './WorldExplorationSection.module.scss';
import serendaleLogo from '../img/worldexploration/serendale-logo.png';
import cvLogo from '../img/worldexploration/crystalvale-logo.png';
import siLogo from '../img/worldexploration/sundered-isles-logo.png';
import BigButton from '../../../components/BigButton';
import ScrollOffset from '../../../components/ScrollOffset';

function WorldExplorationSection() {
  return (
    <section className={styles.worldExploration}>
      <ScrollOffset id="exploration" />
      <div className={styles.copyHeader}>
        <h3>World Exploration</h3>
        <p>Three unique realms to explore, each with their own resources, zones, and objectives.</p>
      </div>
      <div className={styles.serendaleSection}>
        <div className={styles.overlay} />
        <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
          <img src={serendaleLogo} alt="DeFi Kingdoms Serendale" />
          <p>A nostalgic fantasy realm centered around the bustling capital city of Adelyn.</p>
          <BigButton
            className={styles.learnButton}
            label="Learn More"
            onClick={() => {
              window.open(`${window.location.href}serendale`, '_blank', 'noopener')
            }}
          />
        </div>
      </div>
      <div className={styles.crystalvaleSection}>
        <div className={styles.overlay} />
        <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
          <img src={cvLogo} alt="DeFi Kingdoms Crystalvale" />
          <p>
            An icy realm discovered far across the perilous seas where the village of Vithraven has
            just begun to awaken.
          </p>
          <BigButton
            className={styles.learnButton}
            label="Learn More"
            onClick={() => {
              window.open(`${window.location.href}crystalvale`, '_blank', 'noopener')
            }}
            isBlue
          />
        </div>
      </div>
      <div className={styles.sunderedIslesSection}>
        <div className={styles.overlay} />
        <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
          <img src={siLogo} alt="DeFi Kingdoms Sundered Isles" />
          <p>A bustling arena in Submerse where Heroes from across the realms gather to fight for glory</p>
          <BigButton
            className={styles.learnButton}
            label="Learn More"
            onClick={() => {
              window.open(`${window.location.href}sundered-isles`, '_blank', 'noopener')
            }}
            isLightBlue
          />
        </div>
      </div>
    </section>
  );
}

export default WorldExplorationSection;
