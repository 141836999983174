import LearnMoreLink from '../../components/LearnMoreLink'
import styles from './NextCopySection.module.scss'

type NextCopySectionProps = { link: string, label: string }

export function NextCopySection({ link, label }: NextCopySectionProps) {
  return (
    <section className={styles.nextSection}>
      <LearnMoreLink label="Go To Next" link={link} />
      <p>{label}</p>
    </section>
  )
}
