import styles from './BigButton.module.scss';
import cx from 'classnames';

interface BigButtonProps {
  className?: string;
  isBlue?: boolean;
  isLightBlue?: boolean;
  label: string;
  onClick: Function;
}

function BigButton({ className, isBlue, isLightBlue, label, onClick }: BigButtonProps) {
  return (
    <button
      className={cx(styles.bigButton, className, { [styles.blue]: isBlue, [styles.lightBlue]: isLightBlue })}
      onClick={() => onClick()}
    >
      {label}
    </button>
  );
}

export default BigButton;
