import styles from './GetStartedSection.module.scss';
import cx from 'classnames';
import wallet from '../img/gettingstarted/wallets.png';
import bridge from '../img/gettingstarted/bridge.gif';
import join from '../img/gettingstarted/matoya.gif';
import LearnMoreLink from '../../../components/LearnMoreLink';

const gameplayItems = [
  {
    name: 'visit',
    title: '1',
    subtitle: 'Connect Wallet',
    image: wallet,
    link: 'https://game.defikingdoms.com',
    linkLabel: 'Connect Now',
    description: (
      <span>
        Visit{' '}
        <a href="https://game.defikingdoms.com" target="_blank" rel="noreferrer">
          game.defikingdoms.com
        </a>{' '}
        to connect your wallet and automatically configure RPCs for DFK Chain and Kaia.
      </span>
    ),
  },
  {
    name: 'bridge',
    title: '2',
    subtitle: 'Bridge Your Assets',
    image: bridge,
    link: 'https://game.defikingdoms.com/bridge',
    linkLabel: 'Bridge Assets',
    description: (
      <span>
        We support bridges to Kaia and DFKChain from most chains. If you don’t have assets yet,
        use a centralized exchange to send assets directly to Kaia or to Avalanche C-Chain to
        bridge into DFKChain from there
      </span>
    ),
  },
  {
    name: 'trade',
    title: '3',
    subtitle: 'Trade and Get Started',
    image: join,
    link: 'https://game.defikingdoms.com/marketplace',
    linkLabel: 'Start Trading',
    description:
      'Bridging will provide you with a small amount of JEWEL or KAIA, but you’ll need more to continue fueling your transactions. Make a trade with the Trader at the Marketplace and start exploring the Kingdoms!',
  },
];

function GetStartedSection() {
  return (
    <section className={styles.getStarted}>
      <div className={styles.getStartedContainer}>
        <h3>Get Started</h3>
        <div className={styles.gameplayRow}>
          {gameplayItems.map((item, index) => (
            <div
              key={item.name}
              data-aos="fade-up"
              data-aos-delay={(index * 100 + 200).toString()}
              className={cx(styles.gameplayBlock, styles[item.name])}
            >
              <div className={styles.itemCopy}>
                <img src={item.image} alt="" />
                {/* <h3>{item.title}</h3> */}
                <h4>{item.subtitle}</h4>
                <p>{item.description}</p>
              </div>
              <LearnMoreLink label={item.linkLabel} link={item.link} target="_blank" />
              <div className={styles.blockGradient} />
            </div>
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          <a
            href="https://game.defikingdoms.com"
            className={styles.playButton}
            target="_blank"
            rel="noreferrer"
          >
            Get Started
          </a>
        </div>
      </div>
    </section>
  );
}

export default GetStartedSection;
